import React from "react";
import PropTypes from "prop-types"

import "../event-preview/event-preview.sass"
import {absolutePathToSlug} from "../../util/absolutePathToSlug";

const EventPreview = (props) => {
    const { node } = props;

    return (
        <div className={'event-preview'}>
            <a href={`/termin/${absolutePathToSlug(node.fileAbsolutePath)}`} className={'headline medium-link'}>{ node.frontmatter.title }</a>
            <div className="time">
                <img src="/images/time.svg" alt=""/>
                <h6>{ node.frontmatter.time }</h6>
            </div>
            <div className="place">

                <img src="/images/place.svg" alt=""/>
                <p>{ node.frontmatter.location }</p>
            </div>
        </div>
    )
};

EventPreview.propTypes = {
    children: PropTypes.node,
};

export default EventPreview
