import React from "react";
import PropTypes from "prop-types"

import "../homepage-image-gallery/homepage-image-gallery.sass"
import {absolutePathToSlug} from "../../util/absolutePathToSlug";
import {toPath} from "../../util/toPath";

export class HomepageImageGallery extends React.Component {

    constructor(props) {
        super(props);

        const {images} = this.props;
        const galleryImages = images.edges.map(item => {
            if (item.node.frontmatter.galleryImages) {
                return {
                    images: item.node.frontmatter.galleryImages,
                    url: `/aktuelles/${absolutePathToSlug(item.node.fileAbsolutePath)}`,
                    title: item.node.frontmatter.title
                };
            } else if (item.node.frontmatter.thumbnail) {
                return {
                    images: [item.node.frontmatter.thumbnail],
                    url: `/aktuelles/${absolutePathToSlug(item.node.fileAbsolutePath)}`,
                    title: item.node.frontmatter.title
                };
            }
            return undefined;
        });

        // remove empty entries
        for (let i = galleryImages.length; i >= 0; i--) {
            if (!galleryImages[i]) {
                galleryImages.splice(i, 1);
            }
        }
        const initialGallery = galleryImages.map(image => {
            return {
                image: image.images[0],
                url: image.url,
                title: image.title
            };
        });

        const allImages = [];
        galleryImages.forEach(group => {
            group.images.forEach(image => {
                allImages.push({
                    image: image,
                    url: group.url,
                    title: group.title
                })
            })
        });
        this.state = {activeGallery: initialGallery, allImages: allImages};
    }

    componentDidMount() {
        this.swapRandomImage();
    }

    componentWillUnmount() {
        window.clearTimeout(this.imageTimer);
    }

    swapRandomImage() {
        this.imageTimer = window.setTimeout(() => {
            const newGallery = [];
            for (let i = 0; i < 12; i++) {
                newGallery[i] = this.state.activeGallery[i];
            }
            newGallery[this.random(0, 11)] = this.state.allImages[this.random(0, this.state.allImages.length - 1)];

            this.setState({activeGallery: newGallery});

            this.swapRandomImage();
        }, this.random(2000, 5000));
    }

    random(min, max) {
        return Math.floor(Math.random() * (max - min + .99) + min);
    }

    render() {
        const gallery = this.state.activeGallery;

        return (
            <div className={'homepageImageGallery grid'}>
                {
                    (gallery) ? gallery.slice(0, 12).map((image, i) => (
                        <span key={i} className='singleImage'>
                        <img src={toPath("/_prev", image.image)} alt={image.title}/>
                        <a href={image.url}>
                            <div className="singleImage--info">
                                <span className="label">{image.title}</span>
                            </div>
                        </a>
                    </span>
                    )) : ""
                }
            </div>
        )
    }
};

HomepageImageGallery.propTypes = {
    children: PropTypes.node,
};

export default HomepageImageGallery
