import React from "react";
import PropTypes from "prop-types"

import "../homepage-hero/homepage-hero.sass"

const HomepageHero = ({children}) => {

    return (
        <div className={'homepageHero text-container'}>
            <img className={'logo'} src="/images/logo.png" alt=""/>
            <div className="slug">
                <img src="/images/logo-slug1.png" alt=""/>
                <img src="/images/logo-slug2.png" alt=""/>
            </div>
            <p>Der Handels- und Gewerbeverein Erdmannhausen e.V. ist die starke Gemeinschaft von Firmen, Einzelhändlern
                und Dienstleistern in und um Erdmannhausen. Zusammen setzen wir uns für eine lebendige Gemeinde, die
                Stärkung des Standortes und für viele Aktionen und Veranstaltungen über das ganze Jahr ein.</p>
        </div>
    )
};

HomepageHero.propTypes = {
    children: PropTypes.node,
};

export default HomepageHero
